<template>
<!-- web homePaymentOptions -->
<div class="page-content-wrapper">
    <div class="row payment-sec align-items-center me-0">
        <div class="pe-0 col-md-4">
            <h3 class="mb-0">Supported payment options</h3>
        </div>
        <div class="col-md-5">
            <span class="d-flex justify-content-center" style="gap: 5px">
                <img :src="`${s3Url}redev_images/Visa.svg`" alt="" />
                <img :src="`${s3Url}redev_images/Mastercard.svg`" alt="" />
                <img :src="`${s3Url}redev_images/PayPal.svg`" alt="" />
                <img :src="`${s3Url}redev_images/Google+Pay.svg`" alt="" />
                <img :src="`${s3Url}redev_images/ApplePay.svg`" alt="" />
            </span>
        </div>
        <div class="col-md-3 text-end ps-0">
            <button v-if="this.signUpButton == true" type="button" class="btn signUp-btn" data-bs-toggle="modal" data-bs-target="#newBonusSignupModal">
                SIGN UP
            </button>
        </div>
    </div>
</div>
</template>

<script>
import {
    socialImgUrlLink
} from '@/s3bucket';
export default {
    data: () => ({
        s3Url: socialImgUrlLink,
        signUpButton: ""
    }),

    created() {
        // let userSession = "";
        // const cookies = document.cookie.split("; ");
        // for (let i = 0; i < cookies.length; i++) {
        //     const cookie = cookies[i].split("=");
        //     if (cookie[0] === "user_session") {
        //         userSession = cookie[1];
        //         // Use the userSession variable for further processing
        //         //   console.log({ userSession });
        //         // this.$store.commit("SetCurrentLoginObj", userSession);
        //         break;
        //     }
        // }
        this.userSession = localStorage.getItem("user_session");
        if (this.userSession) {
            this.signUpButton = false
        } else {
            this.signUpButton = true
        }

    },
   
}
</script>
