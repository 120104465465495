<template>
<div>
    <div>
        <navBarView></navBarView>
        <homeBanner></homeBanner>
        <mobSignupBonus></mobSignupBonus>
        <SuperFeaturedRaffle></SuperFeaturedRaffle>
        <div class="homeTheme">
        </div>
        <div class="content-bg">
            <homePrizeWonMobile></homePrizeWonMobile>
            <homeCard></homeCard>
            <section class="feedback mb-5 mb-md-auto trustCarouselmb-hide">
                <div class=" py-4 px-4 pb-0">

                    <div class="row categoriesTrustPilot-mb-hide">
                        <div class="col-sm-12 carouselPadHome">
                            <div class='container-xl ratingSecPadHome' onclick="window.location='https://uk.trustpilot.com/review/raffolux.com';" style="margin-top: 0px; background-color: #28293D; border-radius: 10px; margin-bottom: 0px;">
                                <div class="trustpilot-widget" data-locale="en-GB" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5c6d82f060b1cd0001ecd3bd" data-style-height="100%" data-style-width="100%" data-theme="dark" data-stars="5" data-review-languages="en">
                                    <a href="https://uk.trustpilot.com/review/raffolux.com" target="_blank" rel="noopener">Trustpilot</a>
                                </div>
                            </div>
                            <!-- End TrustBox widget -->
                            <div class="trust-bottom-adjustment" style="margin-bottom:-150px"></div>
                        </div>
                    </div>
                </div>

            </section>
            <homePaymentOptions></homePaymentOptions>
            <homePrizeWon></homePrizeWon>
            <!-- <homePaymentOptions></homePaymentOptions> -->
            <!-- <flashCash></flashCash> -->
            <homeAllRaffles></homeAllRaffles>
            <!-- <div class="page-content-wrapper">
                <soldOutRaffles></soldOutRaffles>
            </div> -->
            <footerView></footerView>
        </div>

        <!-- Start of LiveChat (www.livechat.com) code -->
        <noscript>
            <a href="https://www.livechat.com/chat-with/11791575/" rel="nofollow">Chat with us</a>, powered by
            <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>
        </noscript>
        <!-- End of LiveChat code -->
    </div>

    <!--reset password Modal -->
    <div class="modal fade modalBackdropCustom" id="resetPswdModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered forgotPswdStyles">
            <div class="modal-content resetPswdBg">
                <div class="modal-body p-0">
                    <div class="d-flex justify-content-end">
                        <i class="fa-solid fa-xmark signUpCloseMark mb-0" data-bs-dismiss="modal"></i>
                    </div>
                    <h4>Reset password</h4>
                    <div class="mb-0">
                        <label for="password" class="form-label resetPswdLabelNames">Password</label>
                        <input type="password" class="form-control resetPswdInputFields" placeholder="choose a password" id="firstName" aria-describedby="emailHelp" v-model="newPswd" @input="password_check">
                        <div v-if="passwordError" style="color:red" class="mr-0 forgotErrorMsg">
                            {{ passwordError }}
                        </div>
                    </div>
                    <div class="mb-0">
                        <label for="lastName" class="form-label resetPswdLabelNames">Confirm password</label>
                        <input type="password" class="form-control resetPswdInputFields mb-0" placeholder="confirm your password" id="lastName" aria-describedby="emailHelp" v-model="confirmPswd" @input="password_conf">
                        <div v-if="passwordConfError" style="color:red" class="mr-0 forgotErrorMsg">
                            {{ passwordConfError }}
                        </div>
                    </div>

                    <div id="enteredEmailError" v-if="pssError" style="color:red" class="mr-0 mt-4 text-align:center">
                        {{ pssError }}
                    </div>

                    <button type="button" class="btn resetBtn disable" v-if="!isFormFilled" :disabled="isFormFilled">Reset
                        password</button>

                    <button type="button" class="btn resetBtn" @click="reset_pswd()" v-else>Reset password</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import loader from "@/components/ReusableComponents/loader.vue";

import {
    apiURL
} from "@/api/allApis";

import "@/assets/css/style.css";
import "@/assets/css/newHomeBonus.css"
import "../assets/css/signUp.css";

import navBarView from "../components/HomePage/navBarView.vue";
import SuperFeaturedRaffle from "../components/HomePage/superfeaturedRaffle.vue";
import mobSignupBonus from "../components/HomePage/mobSignupBonus.vue";
import menuBar from "../components/HomePage/menuBar.vue";
import homeBanner from "../components/HomePage/homeBanner.vue";
import homeCard from "../components/HomePage/homeCard.vue";
import homePrizeWon from "../components/HomePage/homePrizeWon.vue";
//import flashCash from "../components/HomePage/flashCash.vue";
import homePrizeWonMobile from "../components/HomePage/homePrizeWonMobile.vue";
import homePaymentOptions from "../components/HomePage/homePaymentOptions.vue";
import homeAllRaffles from "@/components/HomePage/homeAllRaffles.vue";
import footerView from "../components/HomePage/footerView.vue";
import $ from "jquery";
import {
    mapActions,
    mapGetters
} from "vuex";

import {
    RaffoluxMixin
} from "@/mixins/pathName.js";
// import soldOutRaffles from "../components/HomePage/soldOutRaffles.vue";

export default {
    name: "home",
    components: {
        navBarView,
        menuBar,
        homeBanner,
        homeCard,
        homePrizeWon,
        homePrizeWonMobile,
        mobSignupBonus,
        homePaymentOptions,
        homeAllRaffles,
        // soldOutRaffles,
        footerView,
        // toggle,
        loader: loader,
        SuperFeaturedRaffle,
    },

    data() {
        return {
            error: "",
            //activeFieldIndex: null,
            isResetPasswordModalVisible: false,
            newPswd: "",
            confirmPswd: "",
            userSession: "",
            pssError: "",
            passwordError: "",
            passwordConfError: "",
            showLiveChat: true,
            utmSource: null,
            utmMedium: null,
            utmCampaign: null,
            utmContent: null,
            utmTerm: null,
            pageTitle: 'We love winners | Raffolux',
            metaDescription: 'Welcome to Raffloux, where you can enter online raffles for a chance to win cash, cars, holidays and more! Our luxury online raffles are cheap and easy to enter.',
            metaKeywords: 'uk raffles, best raffle sites, best raffle website, raffle draw website, online raffle, raffles, online raffle sites, online raffle website, best online raffle, raffle competition website, virtual raffle, raffle games online, win online, raffle competition uk, raffle draw online, best online raffle site',
            isLoading: false,

        };
    },
    mixins: [RaffoluxMixin],
    mounted() {
        this.newReferredUserData = JSON.parse(sessionStorage.getItem('newReferredUser'));
        this.newReferredMobileUserData = JSON.parse(sessionStorage.getItem('newReferredMobileUser'));
        setTimeout(() => {
            if (this.newReferredUserData) {
                this.showSignupModalRef();
            } else if (this.newReferredMobileUserData) {
                this.$router.push("/signUp/");
            }
        }, 2000)

        if (window.location.href.includes("resetToken")) {
            let token = window.location.href.split("?resetToken=")[1].replace(/^%22|(%22)$/g, '').split('&')[0];
            document.cookie = `user_session=${JSON.stringify(token)}`;
            localStorage.setItem("user_session", JSON.stringify(token));
            const isMobile = window.matchMedia("(max-width: 767px)").matches;

            if (isMobile) {
                this.$router.push("/resetPassword");
            } else {
                this.showResetPasswordModal();
            }

        }

        let forgotPassword = localStorage.getItem("isFP");

        if (forgotPassword) {
            this.showSignInModalEmail();
            localStorage.removeItem("isFP");
        }

        let myrafflesModel = localStorage.getItem("myrafflesModel")
        if (myrafflesModel) {
            this.showSignupModal();
            localStorage.removeItem("myrafflesModel");
        }

        let ExistEmailCheck = localStorage.getItem("ExistEmail")
        if (ExistEmailCheck) {
            this.showSignInModalEmail();
        }

    },

    created() {
        this.guest_cart = JSON.parse(localStorage.getItem('get_pointvalue'));
        //  console.log("nnn",this.guest_cart)
        if (this.$route.query.utm_source) {
            this.utmSource = this.$route.query.utm_source;
            localStorage.setItem('utm_source', this.utmSource);
        }

        if (this.$route.query.utm_medium) {
            this.utmMedium = this.$route.query.utm_medium;
            localStorage.setItem('utm_medium', this.utmMedium);
        }

        if (this.$route.query.utm_campaign) {
            this.utmCampaign = this.$route.query.utm_campaign;
            localStorage.setItem('utm_campaign', this.utmCampaign);
        }
        if (this.$route.query.utm_content) {
            this.utmContent = this.$route.query.utm_content;
            localStorage.setItem('utm_content', this.utmContent);
        }
        if (this.$route.query.utm_term) {
            this.utmTerm = this.$route.query.utm_term;
            localStorage.setItem('utm_term', this.utmTerm);
        }
        document.title = this.pageTitle;
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
            metaDescriptionTag.setAttribute('content', this.metaDescription);
        }
        const metaKeywordsTag = document.querySelector('meta[name="keywords"]');
        if (metaKeywordsTag) {
            metaKeywordsTag.setAttribute('content', this.metaKeywords);
        }

        this.userSession = localStorage.getItem("user_session");
        if (this.userSession) {
            this.$store.dispatch("validate_Token", this.userSession);
            this.validateToken();
        } else {
            localStorage.removeItem("user_session");
            const cookieNameToClear = "user_session";
            document.cookie = `${cookieNameToClear}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

            const queryParamsArray = [];
            if (localStorage.getItem('utm_source')) queryParamsArray.push(`utm_source=${localStorage.getItem('utm_source')}`);
            if (localStorage.getItem('utm_medium')) queryParamsArray.push(`utm_medium=${localStorage.getItem('utm_medium')}`);
            if (localStorage.getItem('utm_campaign')) queryParamsArray.push(`utm_campaign=${localStorage.getItem('utm_campaign')}`);
            if (localStorage.getItem('utm_content')) queryParamsArray.push(`utm_content=${localStorage.getItem('utm_content')}`);
            if (localStorage.getItem('utm_term')) queryParamsArray.push(`utm_term=${localStorage.getItem('utm_term')}`);
            const queryParams = queryParamsArray.join('&');
            const homeWithoutLoginURL = queryParams ? `/?${queryParams}` : "/";

            let path = sessionStorage.getItem('tlp')
            if (path) {
                //   console.log(sessionStorage.getItem('tlp'))
                this.$router.push(path);

            } else {
                this.$router.push(homeWithoutLoginURL);
            }

        }
        const currentPath = this.$route.path;
        if (currentPath === '/') {
            this.initializeLiveChat();
        } else {
            const chatWidgetContainer = document.getElementById('chat-widget-container');
            if (chatWidgetContainer) {
                chatWidgetContainer.style.display = 'none';
            }
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

    },

    computed: {
        isFormFilled() {
            return (
                this.newPswd !== "" &&
                this.confirmPswd !== "" &&
                this.passwordError == '' &&
                this.passwordConfError == ''
            );
        },

        ...mapGetters(["getValidateTokenData"]),
        ...mapGetters(['getcartCount']),

    },

    methods: {
        ...mapActions(['fetchCartItems']),
        async fetchCartCount() {
            await this.fetchCartItems(this.userSession);

            this.cartCount = this.getcartCount

        },

        ...mapActions(['validate_Token']),
        async validateToken() {
            await this.validate_Token(this.userSession);
            if (this.getValidateTokenData) {

                const queryParamsArray = [];
                if (localStorage.getItem('utm_source')) queryParamsArray.push(`utm_source=${localStorage.getItem('utm_source')}`);
                if (localStorage.getItem('utm_medium')) queryParamsArray.push(`utm_medium=${localStorage.getItem('utm_medium')}`);
                if (localStorage.getItem('utm_campaign')) queryParamsArray.push(`utm_campaign=${localStorage.getItem('utm_campaign')}`);
                if (localStorage.getItem('utm_content')) queryParamsArray.push(`utm_content=${localStorage.getItem('utm_content')}`);
                if (localStorage.getItem('utm_term')) queryParamsArray.push(`utm_term=${localStorage.getItem('utm_term')}`);
                const queryParams = queryParamsArray.join('&');
                const homepageURL = queryParams ? `/homepage?${queryParams}` : "/homepage";
                this.$router.push(homepageURL);
            } else {
                localStorage.removeItem("user_session");
                const cookieNameToClear = "user_session";
                document.cookie = `${cookieNameToClear}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

                const queryParamsArray = [];
                if (localStorage.getItem('utm_source')) queryParamsArray.push(`utm_source=${localStorage.getItem('utm_source')}`);
                if (localStorage.getItem('utm_medium')) queryParamsArray.push(`utm_medium=${localStorage.getItem('utm_medium')}`);
                if (localStorage.getItem('utm_campaign')) queryParamsArray.push(`utm_campaign=${localStorage.getItem('utm_campaign')}`);
                if (localStorage.getItem('utm_content')) queryParamsArray.push(`utm_content=${localStorage.getItem('utm_content')}`);
                if (localStorage.getItem('utm_term')) queryParamsArray.push(`utm_term=${localStorage.getItem('utm_term')}`);
                const queryParams = queryParamsArray.join('&');
                const homeWithoutLoginURL = queryParams ? `/?${queryParams}` : "/";
                this.$router.push(homeWithoutLoginURL);

            }
        },

        showResetPasswordModal() {
            const modalElement = document.querySelector('#resetPswdModal');
            const tsetymodal = new bootstrap.Modal(modalElement);
            //  console.log(tsetymodal)
            tsetymodal.show();
        },

        showSignupModal() {
            const modalElement = document.querySelector('#newBonusSignupModal');
            const tsetymodal = new bootstrap.Modal(modalElement);
            tsetymodal.show();
        },

        showSignupModalRef() {
            const modalElement = document.querySelector('#newBonusSignupModal');
            const tsetymodal = new bootstrap.Modal(modalElement);
            tsetymodal.show();

        },

        showSignInModalEmail() {
            const modalElement = document.querySelector('#newSignInModal');
            const tsetymodal = new bootstrap.Modal(modalElement);
            tsetymodal.show();
        },

        initializeLiveChat() {
            window.__lc = window.__lc || {};
            window.__lc.license = 11791575;

            (function (n, t, c) {
                function i(n) {
                    return e._h ? e._h.apply(null, n) : e._q.push(n);
                }
                var e = {
                    _q: [],
                    _h: null,
                    _v: "2.0",
                    on: function () {
                        i(["on", c.call(arguments)]);
                    },
                    once: function () {
                        i(["once", c.call(arguments)]);
                    },
                    off: function () {
                        i(["off", c.call(arguments)]);
                    },
                    get: function () {
                        if (!e._h)
                            throw new Error("[LiveChatWidget] You can't use getters before load.");
                        return i(["get", c.call(arguments)]);
                    },
                    call: function () {
                        i(["call", c.call(arguments)]);
                    },
                    init: function () {
                        var n = t.createElement("script");
                        (n.async = !0),
                        (n.type = "text/javascript"),
                        (n.src = "https://cdn.livechatinc.com/tracking.js"),
                        t.head.appendChild(n);
                    },
                };
                !n.__lc.asyncInit && e.init();
                n.LiveChatWidget = n.LiveChatWidget || e;
            })(window, document, [].slice);

        },

        async reset_pswd() {

            this.userSession = localStorage.getItem("user_session");

            if (this.newPswd !== this.confirmPswd || this.confirmPswd !== this.newPswd) {
                this.passwordConfError = "Passwords do not match";
            } else {

                try {
                    await fetch(apiURL.changePassword, {
                            method: "POST",
                            headers: {
                                "Content-type": "application/json; charset=UTF-8",
                                Authorization: JSON.parse(this.userSession),
                            },
                            body: JSON.stringify({
                                password: this.newPswd,
                                confirmPassword: this.confirmPswd,
                            }),
                        })
                        .then((response) => response.json())
                        .then((data) => {
                            this.changePasswordResponse = data;
                            // console.log("this.changePasswordResponse", this.changePasswordResponse)

                            if (this.changePasswordResponse == 200) {

                                localStorage.removeItem("user_session");
                                const cookieNameToClear = "user_session";
                                document.cookie = `${cookieNameToClear}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
                                this.$router.push('/')
                                setTimeout(() => {
                                    window.location.reload();
                                    localStorage.setItem("isFP", true)
                                }, 500);
                                if (this.$route.query.utm_source || this.$route.query.utm_medium || this.$route.query.utm_campaign || this.$route.query.utm_content || this.$route.query.utm_term) {

                                } else {
                                    this.$router.push("/");
                                }
                            } else {
                                this.pssError = this.changePasswordResponse
                            }

                        });
                } catch (error) {
                    // console.log(error);
                }
            }
        },

        password_check() {
            this.pssError = ""

            if (this.newPswd.trim() === "") {
                this.passwordError = "Password is required";
                return false;
            } else if (this.newPswd.trim().length < 6) {
                this.passwordError = "Password must have at least 6 characters";
                return false;
            } else {
                this.passwordError = "";
            }
            return true;
        },

        password_conf() {
            this.pssError = ""

            if (this.confirmPswd.trim() === "") {
                this.passwordConfError = "Please confirm your password";
                return false;
            } else if (this.newPswd !== this.confirmPswd || this.confirmPswd !== this.newPswd) {
                this.passwordConfError = "Passwords do not match";
                return false;
            } else {
                this.passwordConfError = "";
            }
            return true;
        }

    }

};
</script>

<style scoped>
.homeTheme {
    display: none;
}

.css-hv3nvw {
    display: block !important
}

.container-xl {
    max-width: 1240px;
}

.form-control.is-invalid {
    padding-left: 0.75rem
}
</style>
