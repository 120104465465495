<template>

</template>

<script>
import "@/assets/css/homePage.css";
import "@/assets/css/global.css";
import "@/assets/css/sideBar.css";

export default {
    name: "mobileHome",
    components: {},
    data: () => ({
        responseMessage:'',
    }),
    created() {
        try {
            // console.log("Mobile Login", window.location.href);
            let searchParams = window.location.href;

            if (searchParams.includes("authToken")) {
                if (searchParams.includes("_=_")) {
                    searchParams = searchParams.replace("_=_", "");
                } else {
                   // console.log("Valid URL");
                }
                document.cookie = `user_session=${JSON.stringify(
          searchParams.split("authToken=")[1]
        )}`;
        localStorage.setItem("user_session",JSON.stringify(searchParams.split("authToken=")[1]));
            
                this.userSession = localStorage.getItem("user_session");
                this.openApp(this.userSession);
            } else {
                this.$router.push("/userError");
                const urlParams = new URLSearchParams(searchParams.substring(searchParams.indexOf("?") + 1));
                let error = urlParams.get("error")
                if (urlParams.get("error")) {
                    if (error === "access_denied") {
                        this.responseMessage = "Access Denied"
                    } else {
                        this.responseMessage = "User Does Not Exists"
                    }                    
                this.openApp(this.responseMessage);
                }
            }
        } catch (error) {
          //  console.error("Error in created hook:", error);
        }
    },

    methods: {
        // Functions
        openApp(userSession) {
            // console.log("Mobile Login");
            let key = this.responseMessage ? `error`:`authToken`;
            let value = this.responseMessage ? this.responseMessage: userSession;
            const packageName = "com.raffoluxmobile";
            const scheme = "https://test.raffoluxmobile.im/";
            const intentUrl = `intent://raffoluxmobile.com/#Intent;package=${packageName};scheme=${scheme};S.data=${encodeURIComponent(value)};end`;
            // window.location.href = intentUrl;
            window.location.href = `raffoluxmobile://open?${key}=${value}`;

        }
    },
};
</script>
