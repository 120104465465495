export const RaffoluxMixin = {
  methods: {
    isRaffoluxPath() {
      const pathName = window.location.href;
      return (
        pathName.includes("https://raffolux.com") ||
        pathName.includes("https://www.raffolux.com")
        

      //  pathName.includes("https://testing.d21ajyco9f10q5.amplifyapp.com") ||

     // pathName.includes("http://localhost:8080/")
      );
    },
  },
};
